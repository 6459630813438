<template>
  <div>
    <p class="mb-3">Consider the following reaction:</p>

    <p class="mb-3 pl-10">
      <chemical-latex content="A->products" />
    </p>

    <p class="mb-0">
      When the initial concentration of
      <chemical-latex content="A" />
      is increased, the half-life of the reaction is found to
      <span class="text-bold text-danger">increase</span>. What can you say about the order of this
      reaction?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question170d',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {expression: 'It is a zeroth order reaction', value: 'Zeroth'},
        {expression: 'It is a first order reaction', value: 'First'},
        {expression: 'It is a second order reaction', value: 'Second'},
        {
          expression: 'There is not enough information to determine the answer',
          value: 'Unknown',
        },
      ],
    };
  },
};
</script>
